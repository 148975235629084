import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

import Select from 'components/Inputs/Select';

import {
  Button,
  ContentWrapper,
  CustomDropdown,
  CustomDropdownMenu,
  Footer,
  LogoutButton,
  ProfileDataWrapper,
  ProfilePicWrapper,
  Togglebutton,
  UserInformation,
  Username,
  UsernameRA,
} from './styles';

import mockedProfilePic from 'assets/img/mockedProfilePic.png';
import InsideButton from 'components/Button/InsideButton';
import { useModal } from 'contexts/ModalContext';
import { useTheme } from 'contexts/ThemeContext';
import useHandleModal from 'hooks/useHandleModal';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { MdOutlineSwitchAccount } from 'react-icons/md';
import PROFILES from 'utils/profiles';
import metrics from 'utils/theme';

interface FormData {
  profile: string;
}

interface Options {
  id: string;
  value: string;
  code: string;
  disabled?: boolean;
}

function ProfileMenu() {
  const { logOut, user, profile, changeProfile } = useAuth();
  const { setModal } = useModal();
  const { theme } = useTheme();
  const { handleModal } = useHandleModal();

  const navigate = useNavigate();
  const methods = useForm<FormData>();
  const [profileOptions, setProfileOptions] = useState<Options[]>([]);

  const handleLogout = () => {
    logOut();
  };

  const handleMeusDados = () => {
    navigate('/meus-dados');
  };

  const handleClose = () => {
    setModal({
      show: false,
    });
  };

  const onSubmit = (data: FormData) => {
    changeProfile(data.profile);

    handleClose();

    handleModal({
      title: 'Selecione o perfil desejado',
      message: 'Perfil alterado com sucesso',
      icon: 'noIcon',
      confirmButton: {
        text: 'Continuar',
      },
    });

    navigate('/dashboard');
  };

  const handleOptions = () => {
    const options = user?.profiles.map((profile) => {
      return {
        id: profile.id,
        value: profile.name,
        code: profile.code,
        disabled: user?.activeProfileId === profile.id,
      };
    });

    setProfileOptions(options as Options[]);
  };

  useEffect(() => {
    handleOptions();
  }, []);

  function profileChange() {
    setModal({
      show: true,
      title: 'Selecione o perfil desejado',
      component: (
        <>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Select name="profile" label="Perfil" options={profileOptions} />
              <Footer className="d-inline-flex justify-content-end gap-2 w-100">
                <InsideButton
                  onClick={handleClose}
                  text="Voltar"
                  type="button"
                  outline
                  width="233px"
                  borderRadius={metrics.borderRadius.custom}
                />
                <InsideButton
                  text="Salvar"
                  type="submit"
                  width="233px"
                  borderRadius={metrics.borderRadius.custom}
                />
              </Footer>
            </Form>
          </FormProvider>
        </>
      ),
    });
  }

  const handleProfileChange = () => {
    profileChange();
  };

  return (
    <ContentWrapper>
      <CustomDropdown>
        <ProfilePicWrapper>
          <img
            src={user?.photoURL || mockedProfilePic}
            width={33}
            height={33}
            alt="Imagem de perfil do usuário"
          />
        </ProfilePicWrapper>
        <ProfileDataWrapper>
          {profile === PROFILES.STUDENT && (
            <UsernameRA>RA: {user?.ra || '---'}</UsernameRA>
          )}
          <Username margin={profile !== PROFILES.STUDENT ?? true}>
            {user?.name}
          </Username>
        </ProfileDataWrapper>
        <Togglebutton variant="default" className="btn-lg" />
        <CustomDropdownMenu>
          {profile === PROFILES.STUDENT ? (
            <UserInformation>
              <p>Escola: {user?.escolaName}</p>
              <p>Ano letivo: {user?.anoletivo}</p>
              <p>Turma: {user?.turma}</p>
            </UserInformation>
          ) : profile === PROFILES.TEACHER ? (
            <UserInformation>
              <p>Instituição: {user?.instituicao}</p>
              <p>Escola: {user?.escolaName}</p>
              <p>Materia: {user?.materia}</p>
              <p>Turma: {user?.turma}</p>
              <p>Ano letivo: {user?.anoletivo}</p>
            </UserInformation>
          ) : profile === PROFILES.ADMIN_REGIONAL ? (
            <UserInformation>
              <p>Instituição: {user?.instituicao}</p>
              {user?.escolas.length &&
                user?.escolas.map((escola) => <p key={escola}>{escola}</p>)}
            </UserInformation>
          ) : null}
          {profile !== PROFILES.STUDENT && (
            <Button as="button" onClick={handleProfileChange}>
              <MdOutlineSwitchAccount size={25} color={theme.darkgray} />
              Troca de perfil
            </Button>
          )}
          <Button as="button" onClick={handleMeusDados}>
            <CgProfile size={25} color={theme.darkgray} /> Meus dados
          </Button>
          <Dropdown.Divider />
          <LogoutButton as="button" onClick={handleLogout}>
            <FiLogOut size={25} /> Sair
          </LogoutButton>
        </CustomDropdownMenu>
      </CustomDropdown>
    </ContentWrapper>
  );
}

export default ProfileMenu;
