import WhiteLogo from 'components/WhiteLogo';
import { useAuth } from 'contexts/AuthContext';
import { Fragment, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import links from 'utils/links';
import metrics from 'utils/theme';

import { useTheme } from 'contexts/ThemeContext';
import {
  Arrow,
  ContainerLogo,
  ContainerSubmenu,
  CustomLinkSubmenu,
  CustomLinkThree,
  Customlink,
  IconDiv,
  LinkContainer,
  ListItem,
  ListItemSubmenu,
  SideBar,
  Title,
  TitleSubmenu,
  TitleWrapper,
} from './styles';

interface SubmenuProps {
  path: string;
  active: boolean;
  disabled?: boolean;
}

function Aside() {
  const { profile } = useAuth();
  const { whiteLabel } = useTheme();
  const [getLinks, setLinks] = useState(links);

  const [submenu, setSubmenu] = useState<SubmenuProps[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const pathname = window.location.pathname;

  useEffect(() => {
    const getLink = getLinks.map((link) => {
      return {
        path: link.path,
        active: false,
        disabled: link.disabled,
      };
    });

    setSubmenu(getLink);
  }, [getLinks]);

  useEffect(() => {
    const pathsToRemove: string[] = [];
    if (!whiteLabel.habilitarAulaAoVivo) {
      pathsToRemove.push('/aluno/aula-ao-vivo', '/professor/aula-ao-vivo');
    }
    if (!whiteLabel.habilitarMetas) {
      pathsToRemove.push('/metas', '/plano');
    }
    if (
      profile === 'TEACHER' &&
      !whiteLabel.habilitarProfessoresAdicionarUsuarios
    ) {
      pathsToRemove.push('/clientes');
    }
    if (!whiteLabel.habilitarCalendario) {
      pathsToRemove.push('/agendar-atividade');
    }
    if (!whiteLabel.habilitarGraficos) {
      pathsToRemove.push('/meus-graficos');
    }
    if (!whiteLabel.habilitarRanking) {
      pathsToRemove.push('/ranking');
    }
    if (!whiteLabel.habilitarTickets) {
      pathsToRemove.push('/ticket/meus-tickets', '/ticket/geral');
    }
    if (
      !whiteLabel.habilitarSuporte &&
      profile !== 'ANALYTICA' &&
      profile !== 'TEACHER_ANALYTICA'
    ) {
      pathsToRemove.push('/ticket/meus-tickets', '/ticket/geral');
    }
    if (
      !whiteLabel.habilitarComunicacao &&
      profile !== 'ANALYTICA' &&
      profile !== 'TEACHER_ANALYTICA'
    ) {
      pathsToRemove.push('/comunicacao');
    }
    if (
      !whiteLabel.habilitarBoletim &&
      profile !== 'ANALYTICA' &&
      profile !== 'TEACHER_ANALYTICA'
    ) {
      pathsToRemove.push('/boletim');
    }
    const filterLinks = getLinks.filter(
      (link) => !pathsToRemove.includes(link.path)
    );

    setLinks([...filterLinks]);
  }, []);

  const toggleLink = (link: string) => {
    if (!!submenu.find((item) => item.path === link)?.active) {
      setIsOpen(true);
    }

    const holdLinks = [...submenu];
    const index = holdLinks.findIndex((item) => item.path === link);

    holdLinks[index].active = !holdLinks[index].active;

    setSubmenu(holdLinks);
  };

  const showSubmenu = (path: string, instituicaoId?: string) => {
    if (
      instituicaoId === 'b24d6d5a-da36-4c14-8919-10fd94e8cdf2' &&
      path === '/comunicacao/duvidas'
    )
      return false;
    return true;
  };

  return (
    <SideBar className="d-lg-block sidebar collapse">
      <ContainerLogo>
        <WhiteLogo />
      </ContainerLogo>
      <ul className="nav flex-column mt-5">
        {getLinks.map((link) => {
          if (profile && link.profiles.includes(profile)) {
            if (link.submenu && link.submenu.length) {
              return (
                <Fragment key={link.path}>
                  <ListItem
                    className="nav-item"
                    onClick={() => toggleLink(link.path)}
                    isActive={pathname === link.path}
                    isSelected={
                      !!submenu.find((item) => item.path === link.path)
                        ?.active && pathname.includes(link.path)
                    }
                  >
                    <LinkContainer disabled={link.disabled}>
                      <CustomLinkThree>
                        <TitleWrapper margin="3px" className="li-item">
                          <IconDiv>{link.icon}</IconDiv>
                          <Title
                            isActive={pathname === link.path}
                            margin={metrics.spacings.xsmall}
                          >
                            {link.name}
                          </Title>
                        </TitleWrapper>
                      </CustomLinkThree>
                    </LinkContainer>
                    <Arrow
                      isActive={
                        !!submenu.find((item) => item.path === link.path)
                          ?.active
                      }
                    >
                      {isOpen ? (
                        <MdKeyboardArrowUp color="white" size={24} />
                      ) : (
                        <MdKeyboardArrowDown color="white" size={24} />
                      )}
                    </Arrow>
                  </ListItem>

                  <ContainerSubmenu
                    isActive={
                      !!submenu.find((item) => item.path === link.path)?.active
                    }
                  >
                    {link.submenu.map((subLink) => {
                      if (profile && subLink.profiles.includes(profile)) {
                        if (
                          !showSubmenu(subLink.path, whiteLabel.instituicaoId)
                        )
                          return <></>;
                        return (
                          <ListItemSubmenu
                            key={subLink.path}
                            isActive={pathname === subLink.path}
                            className="nav-item"
                          >
                            <CustomLinkSubmenu to={subLink.path}>
                              <TitleWrapper margin="3px">
                                <TitleSubmenu
                                  isActive={pathname === subLink.path}
                                  margin={metrics.spacings.xsmall}
                                >
                                  {subLink.name}
                                </TitleSubmenu>
                              </TitleWrapper>
                            </CustomLinkSubmenu>
                          </ListItemSubmenu>
                        );
                      }
                    })}
                  </ContainerSubmenu>
                </Fragment>
              );
            }

            if (
              whiteLabel.instituicaoId !==
                'b24d6d5a-da36-4c14-8919-10fd94e8cdf2' &&
              link.path === 'download'
            ) {
              return <></>;
            }

            return (
              <ListItem
                key={link.path}
                className="nav-item"
                isActive={pathname === link.path}
              >
                <LinkContainer disabled={link.disabled}>
                  <Customlink
                    to={
                      link.path === 'download'
                        ? 'https://cdn.analyticaensino.com.br/Curr%C3%ADculo%20Paran%C3%A1%20-%20Lista%20de%20Habilidades.pdf'
                        : link.path
                    }
                  >
                    <TitleWrapper margin="3px">
                      <IconDiv>{link.icon}</IconDiv>
                      <Title
                        isActive={pathname === link.path}
                        margin={metrics.spacings.xsmall}
                      >
                        {link.name}
                      </Title>
                    </TitleWrapper>
                  </Customlink>
                </LinkContainer>
              </ListItem>
            );
          }
        })}
      </ul>
    </SideBar>
  );
}

export default Aside;
